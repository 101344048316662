import * as React from "react";
import Cookies from "js-cookie";
import { Button } from "./Button";
import { ModernTheme } from "./Theme";
import { AnimatePresence, motion } from "framer-motion";
import { T } from "./T";
import { useLocation } from "react-router-dom";

export let ToastManagerInstance: ToastManager | null = null;
let _allowToasts: boolean = true;
export const setAllowToasts = (allow: boolean) => {
  _allowToasts = allow;
};

class ToastManager {
  private toasts: { [id: string]: JSX.Element };
  private setToasts: (_: Array<JSX.Element>) => void;
  private keyCounter: number = 0;

  constructor(setToasts: (_: Array<JSX.Element>) => void) {
    this.setToasts = setToasts;
    this.toasts = {};
    this.updateView();
  }

  private updateView() {
    this.setToasts(Object.values(this.toasts));
  }

  public dropToast(managerKey: number) {
    delete this.toasts[managerKey];
    this.updateView();
  }

  public showToast(props: ToastProps) {
    if (!_allowToasts) {
      return;
    }

    // check if cookie set
    if (props.cookieKey && Cookies.get(props.cookieKey)) return;

    let key = this.keyCounter++;
    this.toasts[key] = <Toast {...props} managerKey={key} key={key} />;
    this.updateView();
  }

  public showSimpleToast(message: string) {
    this.showToast({
      header: "Notice",
      icon: <i className="bi bi-info-circle-fill"></i>,
      children: <>{message}</>,
    });
  }
}

export type ToastProps = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  autoHide?: number;
  cookieKey?: string;
  theme?: ModernTheme;
};

const ToastThemeStyles = {
  [ModernTheme.Red]:
    "bg-gradient-to-tr from-jgu-red/95 via-jgu-red-85/95 to-jgu-red-55/95 text-jgu-white",
  [ModernTheme.Green]:
    "bg-gradient-to-tr from-jgu-green/95 via-jgu-green-85/95 to-jgu-green-55/95 text-jgu-white",
  [ModernTheme.Blue]:
    "bg-gradient-to-tr from-jgu-blue/95 via-jgu-blue-85/95 to-jgu-blue-55/95 text-jgu-white",
  [ModernTheme.Purple]:
    "bg-gradient-to-tr from-jgu-purple/95 via-jgu-purple-85/95 to-jgu-purple-55/95 text-jgu-white",
  [ModernTheme.Yellow]:
    "bg-gradient-to-tr from-jgu-yellow/95 via-jgu-yellow-85/95 to-jgu-yellow-55/95 text-jgu-black-dark",
  [ModernTheme.Orange]:
    "bg-gradient-to-tr from-jgu-orange/95 via-jgu-orange-85/95 to-jgu-orange-55/95 text-jgu-white",
  [ModernTheme.Gray]:
    "bg-gradient-to-tr from-jgu-gray/95 via-jgu-gray-85/95 to-jgu-gray-55/95 text-jgu-white",
  [ModernTheme.Black]:
    "bg-gradient-to-tr from-jgu-black/95 via-jgu-black-85/95 to-jgu-black-55/95 text-jgu-white",
  [ModernTheme.Transparent]: "bg-transparent text-jgu-gray",
} as { [key in ModernTheme]: string };

export const Toast = (
  props: ToastProps & {
    managerKey: number;
  },
) => {
  const onDismiss = () => {
    if (props.cookieKey) {
      Cookies.set(props.cookieKey, "dismissed", {
        expires: 365 * 2,
      });
    }
    ToastManagerInstance!.dropToast(props.managerKey);
  };

  const dismiss = () => {
    onDismiss();
  };

  React.useEffect(() => {
    if (props.autoHide != undefined) {
      setTimeout(() => {
        dismiss();
      }, props.autoHide);
    }
  }, []);

  return (
    <motion.div
      className={`z-[2] w-full overflow-hidden rounded-xl border-0 text-neutral-50 md:w-96`}
      style={{
        height: props.children ? "14rem" : "fit-content",
        maxHeight: "14rem",
      }}
      transition={{}}
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
    >
      <div
        className={[
          "m-0 flex h-full flex-col p-2",
          ToastThemeStyles[props.theme ? props.theme : ModernTheme.Orange],
        ].join(" ")}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {props.icon ? (
              <div className="p-2 pl-3 pr-3">{props.icon}</div>
            ) : null}
            <div className="m-0 p-0">{props.header}</div>
          </div>

          <Button theme={ModernTheme.Transparent} onClick={dismiss}>
            <i className="bi bi-x text-3xl"></i>
          </Button>
        </div>
        <div
          className={[
            "grow overflow-hidden",
            "flex items-center",
            props.children ? "" : "hidden",
          ].join(" ")}
        >
          {props.children ? (
            <div className="m-0 flex-row p-3">
              <div className="m-0 p-0">{props.children}</div>
            </div>
          ) : undefined}
        </div>
      </div>
    </motion.div>
  );
};

export const ToastManagerComponent = (props: { doNotAddDefault?: boolean }) => {
  const [toasts, setToasts] = React.useState<Array<JSX.Element>>([]);

  const location = useLocation();

  React.useEffect(() => {
    ToastManagerInstance = new ToastManager(setToasts);
    if (props.doNotAddDefault && !location.pathname.match(/monitor/)) {
      ToastManagerInstance!.showToast({
        icon: <i className="bi bi-lock"></i>,
        children: (
          <T
            de="Wir verweden Cookies um dein Websiteerlebnis zu personalisieren."
            en="We use cookies to personalize your website experience."
          />
        ),
        header: "Cookies",
        cookieKey: "cookieMessage",
      });
    }
  }, []);

  return (
    <div className="fixed bottom-0 right-0 z-50 p-3">
      <div className="flex flex-col gap-4">
        <AnimatePresence>{toasts}</AnimatePresence>
      </div>
    </div>
  );
};
