import { useEffect } from "react";
import { JGUColors } from "../components/ui/Theme";
import convert from "color-convert";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePreferredLanguage } from "../../main-web/WebApp";
import React from "react";
import { PostMeta } from "../components/web/admin/AdminPostList";
import { PTree } from "../components/ui/posterTree/PTree";
import { timeout } from "./WorkerUtility";

export const makeColorHook = (hexRgb: string) => {
  return () => {
    document.documentElement.style.removeProperty("--dm-accent");
    document.documentElement.style.setProperty("--dm-accent", hexRgb);
  };
};

export const withColor = (Component: React.FC, color?: string) => {
  return () => {
    useEffect(() => {
      makeColorHook(color || JGUColors.Red)();
    }, []);
    return <Component />;
  };
};

export const adjustedAccentColor = (color: string, intensity: number) => {
  const [h, s, _] = convert.hex.hsl(color);
  const [r, g, b] = convert.hsl.rgb([h, s, 80]);

  return "rgba(" + r + "," + g + "," + b + "," + intensity / 100 + ")";
};

type ExhibitionData = {
  [key: string]: {
    name: string;
    namespace: string;
    url: string;
    exhibitions: { [key: string]: any };
  };
};
export const useExhibitions = () => {
  const lang = usePreferredLanguage();
  return useQuery({
    queryKey: ["exhibitions"],
    queryFn: async () => {
      const data: {
        name: string;
        namespace: string;
        url: string;
      }[] = await fetch("/api/namespaces").then((res) => res.json());

      data.sort((a, b) => {
        return a.namespace == "dsds" ? -1 : b.namespace == "dsds" ? 1 : 0;
      });

      const exhibitionData: ExhibitionData = {};
      for (let i = 0; i < data.length; i++) {
        exhibitionData[data[i].namespace] = {
          ...data[i],
          exhibitions: await fetch(
            "/api/exhibitions?namespace=" +
              data[i].namespace +
              "&language=" +
              lang,
          ).then((res) => res.json()),
        };
      }
      return exhibitionData;
    },
    staleTime: 5000, // 3 s
  });
};

type AnalyticsData = {
  visits: number;
  comments: number;
  visits_per_day: any;
  unique_visits_per_day: any;
  unique_agents: any;
  page_visits: any;
  unique_page_visits: any;
  analytics: Array<{
    id: number;
    uri: string;
    ip: string;
    country: string;
    created: string;
  }>;
  countries: Array<{
    country: string;
    count: number;
  }>;
};

export const useAnalyticsSummary = () => {
  return useQuery({
    queryKey: ["analytics", "summary"],
    queryFn: async () => {
      const response = await fetch("/api/analytics/summary");
      const data = await response.json();

      // remove 30 days
      const today = new Date();
      let prior = new Date(new Date().setDate(today.getDate() - 29));

      let newVisitsPerDay: any[] = [];
      let newUniqueVisitsPerDay: any[] = [];
      while (prior <= today) {
        let found = false;
        for (let i = 0; i < data.visits_per_day.length; ++i) {
          const date = new Date(data.visits_per_day[i]["DATE(created)"]);
          if (date.toDateString() == prior.toDateString()) {
            newVisitsPerDay.push(data.visits_per_day[i]);
            found = true;
            break; // entry only exists once
          }
        }

        if (!found)
          newVisitsPerDay.push({
            "DATE(created)": `${prior.getFullYear()}-${("00" + (prior.getMonth() + 1)).slice(-2)}-${("00" + prior.getDate()).slice(-2)}`,
            count: 0,
          });

        found = false;
        for (let i = 0; i < data.unique_visits_per_day.length; ++i) {
          const date = new Date(data.unique_visits_per_day[i]["DATE(created)"]);
          if (date.toDateString() == prior.toDateString()) {
            newUniqueVisitsPerDay.push(data.unique_visits_per_day[i]);
            found = true;
            break; // entry only exists once
          }
        }

        if (!found)
          newUniqueVisitsPerDay.push({
            "DATE(created)": `${prior.getFullYear()}-${("00" + (prior.getMonth() + 1)).slice(-2)}-${("00" + prior.getDate()).slice(-2)}`,
            count: 0,
          });

        prior = new Date(prior.setDate(prior.getDate() + 1));
      }

      data.visits_per_day = newVisitsPerDay;
      data.unique_visits_per_day = newUniqueVisitsPerDay;

      // filter all countries where country == null
      data.countries = data.countries.filter(
        (item: any) => item.country != null,
      );
      data.countries = data.countries.filter(
        (item: any) => item.country != "Unknown",
      );
      data.countries = data.countries.filter(
        (item: any) => item.country != undefined,
      );
      data.countries = data.countries.filter(
        (item: any) => item.country != "undefined",
      );

      return data as AnalyticsData;
    },
  });
};

type PostData = {
  data: Array<PostMeta>;
  limit: number;
  largestID: number;
  smallestID: number | null;
  showEndRefresh: boolean;
};

export const usePosts = () => {
  const queryClient = useQueryClient();

  const update = async (noSet?: boolean) => {
    let { data, limit, largestID, smallestID } =
      queryClient.getQueryData<PostData>(["posts"]) || {
        data: [],
        limit: 6,
        largestID: 0,
        smallestID: null,
        showEndRefresh: true, // doesn't matter
      };

    const res = await fetch(`/api/posts/get?limit=${limit}&until=${largestID}`);
    const json: {
      data: Array<PostMeta>;
      limit: number;
      lowest: number;
    } = await res.json();

    limit = json.limit;

    // load new data
    const serverData = json.data;

    const newData = new Array<PostMeta>();
    for (let i = 0; i < serverData.length; ++i) {
      newData.push(serverData[i]);
      largestID = Math.max(largestID, serverData[i].id);
      smallestID = Math.min(smallestID ?? serverData[i].id, serverData[i].id);
    }

    // integrate old data
    for (let i = 0; i < data.length; ++i) {
      newData.push(data[i]);
    }

    // update query
    const newPostData: PostData = {
      data: newData,
      limit,
      largestID,
      smallestID,
      showEndRefresh: json.lowest != smallestID,
    };

    if (!noSet) queryClient.setQueryData(["posts"], newPostData);
    return newPostData;
  };

  const updateEnd = async () => {
    let { data, limit, largestID, smallestID } =
      queryClient.getQueryData<PostData>(["posts"]) || {
        data: [],
        limit: 6,
        largestID: 0,
        smallestID: 0,
        showEndRefresh: true,
      };

    const res = await fetch(
      `/api/posts/get?limit=${limit}&largest=${largestID}&start=${smallestID}`,
    );
    const json: {
      data: Array<PostMeta>;
      limit: number;
      lowest: number;
    } = await res.json();

    limit = json.limit;

    const serverData = json.data;
    const newData = new Array<PostMeta>();

    for (let i = 0; i < data.length; ++i) {
      newData.push(data[i]);
    }

    for (let i = 0; i < serverData.length; ++i) {
      newData.push(serverData[i]);
      largestID = Math.max(largestID, serverData[i].id);
      smallestID = Math.min(smallestID ?? serverData[i].id, data[i].id);
    }

    const newPostData: PostData = {
      data: newData,
      limit,
      largestID,
      smallestID,
      showEndRefresh: json.lowest != smallestID,
    };
    queryClient.setQueryData(["posts"], newPostData);
    return newPostData;
  };

  const queryObj = useQuery({
    queryKey: ["posts"],
    queryFn: async () => {
      return await update(true);
    },
  });

  return {
    ...queryObj,
    update,
    updateEnd,
  };
};

export const usePostBackground = (id: number) => {
  return useQuery({
    queryKey: ["postBackground", id],
    queryFn: async () => {
      const res = await fetch(`/api/posts/getBackgroundForId?id=${id}`);
      let text = await res.json();
      return text;
    },
  });
};

export const usePostData = (id: number) => {
  const lang = usePreferredLanguage();
  return useQuery({
    queryKey: ["postData", id],
    queryFn: async () => {
      const res = await fetch(`/api/posts/getForId?id=${id}`);
      const data: PostMeta & {
        content: string;
      } = await res.json();
      const content = JSON.parse(data.content);
      return {
        data,
        content: content as {
          background: string; // todo: storage optimization (do not fetch directly from server)
          tree: PTree;
        },
        title: lang == "en" ? data.titleEN : data.titleDE,
      };
    },
  });
};

type CommentMeta = {
  id: number;
  exhibition: string;
  namespace: string;
  text: string;
  user: string;
  created: string; // 2023-03-27 12:44:01
};

type CommentData = {
  data: Array<CommentMeta>;
  limit: number;
  largestID: number;
  smallestID: number | null;
  showEndRefresh: boolean;
};

export const useComments = (
  urlNamespace: string,
  urlName: string,
  autoUpdate?: boolean,
) => {
  const queryClient = useQueryClient();

  const update = async (ret?: boolean) => {
    let first = true;
    while (first || autoUpdate) {
      try {
        let { data, limit, largestID, smallestID, showEndRefresh } =
          queryClient.getQueryData<CommentData>([
            "comments",
            urlNamespace,
            urlName,
          ]) || {
            data: [],
            limit: 6,
            largestID: 0,
            smallestID: null,
            showEndRefresh: true,
          };

        const res = await fetch(
          `/api/comment/get?namespace=${encodeURIComponent(urlNamespace)}&exhibition=${encodeURIComponent(urlName)}&until=${largestID}`,
        );
        const json: {
          data: [CommentMeta];
          limit: number;
          lowest: number;
        } = await res.json();

        const serverData = json.data;
        limit = json.limit;
        const newData: Array<CommentMeta> = new Array<CommentMeta>();

        for (let i = 0; i < serverData.length; ++i) {
          newData.push(serverData[i]);
          largestID = Math.max(largestID, serverData[i].id);
          smallestID = Math.min(
            smallestID ?? serverData[i].id,
            serverData[i].id,
          );
        }
        for (let i = 0; i < data.length; ++i) {
          newData.push(data[i]);
        }
        showEndRefresh = json.lowest != smallestID;

        const newCommentData: CommentData = {
          data: newData,
          limit,
          largestID,
          smallestID,
          showEndRefresh,
        };

        if (ret) return newCommentData;
        queryClient.setQueryData(
          ["comments", urlNamespace, urlName],
          newCommentData,
        );
      } catch (e) {
        console.log("Last update failed.");
        await timeout(1000);
        continue;
      }
      first = false;
      await timeout(5000);
    }
  };

  const updateEnd = async () => {
    try {
      let { data, limit, largestID, smallestID, showEndRefresh } =
        queryClient.getQueryData<CommentData>([
          "comments",
          urlNamespace,
          urlName,
        ]) || {
          data: [],
          limit: 6,
          largestID: 0,
          smallestID: 0,
          showEndRefresh: true,
        };

      const res = await fetch(
        `/api/comment/get?namespace=${encodeURIComponent(urlNamespace)}&exhibition=${encodeURIComponent(urlName)}&until=${0}&start=${smallestID}`,
      );
      const json: {
        data: [CommentMeta];
        limit: number;
        lowest: number;
      } = await res.json();
      const serverData = json.data;
      limit = json.limit;
      const newData: Array<CommentMeta> = new Array<CommentMeta>();
      for (let i = 0; i < data.length; ++i) {
        newData.push(data[i]);
      }
      for (let i = 0; i < serverData.length; ++i) {
        newData.push(data[i]);
        largestID = Math.max(largestID, data[i].id);
        smallestID = Math.min(smallestID ?? data[i].id, data[i].id);
      }
      showEndRefresh = json.lowest != smallestID;

      const newCommentData: CommentData = {
        data: newData,
        limit,
        largestID,
        smallestID,
        showEndRefresh,
      };

      queryClient.setQueryData(
        ["comments", urlNamespace, urlName],
        newCommentData,
      );
    } catch (e) {
      console.log("Last end update failed.");
    }
  };

  const queryObj = useQuery({
    queryKey: ["comments", urlNamespace, urlName],
    queryFn: async () => {
      return await update(true);
    },
  });

  return {
    ...queryObj,
    update,
    updateEnd,
  };
};
