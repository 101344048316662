import { useLitteraMethods } from "@assembless/react-littera";
import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { OffcanvasComponent } from "../sources/components/ui/Offcanvas";
import {
  ToastManagerComponent,
  ToastManagerInstance,
  setAllowToasts,
} from "../sources/components/ui/ToastManager";

import Cookies from "js-cookie";
import MUSEUM_LOGO from "../resources/images/MUSEUM_LOGO.webp";
import { RequireAuth } from "../sources/utility/Authentication";
import { T } from "../sources/components/ui/T";
import { ModernTheme } from "../sources/components/ui/Theme";
import { AppLogo } from "../sources/components/ui/NavigationBar";
import { ChristmasSlide } from "../sources/components/monitor/slides/ChristmasSlide";

const Footer = React.lazy(() => import("../sources/components/ui/Footer"));
const NavigationBar = React.lazy(
  () => import("../sources/components/ui/NavigationBar"),
);
const ExhibitionLoadingScreen = React.lazy(
  () => import("../sources/components/ui/LoadingScreen"),
);
const NotFoundPage = React.lazy(
  () => import("../sources/components/web/pages/ErrorPage"),
);
const Commentary = React.lazy(
  () => import("../sources/components/web/pages/DisplayPage"),
);
const Home = React.lazy(
  () => import("../sources/components/web/pages/HomePage"),
);
// const Software = React.lazy(
//   () => import("../sources/components/web/pages/SoftwarePage"),
// );
const About = React.lazy(
  () => import("../sources/components/web/pages/AboutPage"),
);
const Game = React.lazy(
  () => import("../sources/components/web/pages/GamePage"),
);
const Monitor = React.lazy(
  () => import("../sources/components/web/pages/MonitorPage"),
);
const PocketMonitor = React.lazy(
  () => import("../sources/components/web/pages/SpaiseplanPage"),
);
const MonitorMensa = React.lazy(
  () => import("../sources/components/web/pages/MonitorMensaPage"),
);
const MonitorChristmas = React.lazy(
  () => import("../sources/components/web/pages/MonitorChristmasPage"),
);

const Admin = React.lazy(
  () => import("../sources/components/web/pages/AdminPage"),
);
const Moderation = React.lazy(
  () => import("../sources/components/web/pages/ModerationPage"),
);
const LegacyCampus = React.lazy(
  () => import("../sources/components/web/pages/LegacyCampus"),
);

export const usePreferredLanguage = () => {
  return (Cookies.get("language") ??
    (navigator.language.match(/de/) ? "de" : "en")) as "de" | "en";
};

const LoadingScreen = (props: {
  pad?: boolean;
}) => {
  return (
    <div
      className={
        "fullbody flex h-[100dvh] w-full " + (props.pad ? "pt-20" : "")
      }
    >
      <div className={
        "h-full w-full flex flex-col items-center justify-center gap-2 bg-gradient-to-tr from-jgu-red-20 via-jgu-orange-20 to-jgu-purple-20 shadow-glow"
      }>
                    <div className="grayscale opacity-20 scale-[200%] ">
              <div className="animate-pulse"><AppLogo noText /></div>
            </div>
      </div>
    </div>
  );
};

const ExhibitionNavigate = (props: { dir: string }) => {
  const lang = usePreferredLanguage();
  const params = useParams();
  const urlNamespace = params["dir"];
  const urlName = params["name"];
  console.log("Navigate to exhibition", lang, props.dir, urlNamespace, urlName);
  return (
    <Navigate
      replace
      to={`/${lang}/${props.dir}/${urlNamespace}/${urlName}${window.location.search}`}
    />
  );
};

const PostNavigate = () => {
  const lang = usePreferredLanguage();
  const params = useParams();
  const urlId = params["id"];
  return (
    <Navigate replace to={`/${lang}/post/${urlId}${window.location.search}`} />
  );
};

const waitForToastManager = (message: string) => {
  if (!ToastManagerInstance) {
    setTimeout(() => {
      waitForToastManager(message);
    }, 1000);
  } else {
    ToastManagerInstance.showToast({
      icon: <i className="bi bi-exclamation-triangle-fill"></i>,
      header: <T de="Hinweis" en="Notice" />,
      autoHide: 6000,
      theme: ModernTheme.Yellow,
      children: <div className="text-neutral-800">{message}</div>,
    });
  }
};

const ScrollToTopOnNavigate = (props: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
};

let country: string | undefined = undefined;

const App = () => {
  const searchParams = useSearchParams();
  const location = useLocation();
  const methods = useLitteraMethods();
  const preferredLanguage = usePreferredLanguage();

  useEffect(() => {
    if (searchParams[0].has("noToast")) {
      setAllowToasts(false);
    }

    if (location.pathname.match(/\/de\/?$/)) {
      methods.setLocale("de_DE");
      Cookies.set("language", "de");
    } else if (location.pathname.match(/\/en\/?$/)) {
      methods.setLocale("en_US");
      Cookies.set("language", "en");
    } else {
      if (preferredLanguage == "de") {
        methods.setLocale("de_DE");
        Cookies.set("language", "de");
      } else {
        methods.setLocale("en_US");
        Cookies.set("language", "en");
      }
    }

    (async () => {
      if (country === undefined) {
        // get country from ip
        const req = await fetch("https://ipapi.co/json/");
        const res = await req.json();
        country = res.country_name;
      }

      await fetch(
        `/api/analytics?url=${encodeURIComponent(location.pathname + location.search)}&country=${country}`,
      );
    })();

    const queryParameters = new URLSearchParams(window.location.search);
    const message = queryParameters.get("message");
    if (message) {
      waitForToastManager(message);
    }
    queryParameters.delete("message");
  }, []);

  const makeBaseRoute = (basePath: string) => {
    return [
      <Route
        key={"0" + basePath}
        element={
          <div
            className={
              "flex flex-col " +
              (searchParams[0].has("overflow")
                ? "max-h-screen overflow-hidden"
                : "")
            }
          >
            {searchParams[0].has("hideNavbar") ? (
              <></>
            ) : (
              <React.Suspense fallback={<></>}>
                <NavigationBar />
              </React.Suspense>
            )}
            <main className="grow bg-jgu-red-10">
              <ScrollToTopOnNavigate>
                <Outlet />
              </ScrollToTopOnNavigate>
            </main>
            {searchParams[0].has("overflow") ? (
              <div
                className="z-1 fixed bottom-0 left-0 right-0 h-[30vh] bg-gradient-to-t from-neutral-50 to-transparent"
                style={{ pointerEvents: "none" }}
              ></div>
            ) : (
              <></>
            )}
            {searchParams[0].has("hideFooter") ? (
              <></>
            ) : (
              <React.Suspense fallback={<></>}>
                <Footer
                  className={
                    searchParams[0].has("overflow")
                      ? "z-2 fixed bottom-0 left-0 right-0 bg-neutral-50"
                      : ""
                  }
                />
              </React.Suspense>
            )}
          </div>
        }
      >
        <Route
          path={basePath}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <Home />
            </React.Suspense>
          }
        />

        <Route
          path={`${basePath}/about`}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <About />
            </React.Suspense>
          }
        />

        <Route
          path={`${basePath}/mensa`}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <PocketMonitor />
            </React.Suspense>
          }
        />

        <Route
          path={`${basePath}/404`}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <NotFoundPage />
            </React.Suspense>
          }
        />

        <Route
          path={`${basePath}/legacyCampus`}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <LegacyCampus />
            </React.Suspense>
          }
        />

        <Route
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route
            path={`${basePath}/admin`}
            element={
              <React.Suspense fallback={<LoadingScreen />}>
                <Admin />
              </React.Suspense>
            }
          />

          <Route
            path={`${basePath}/admin/moderation`}
            element={
              <React.Suspense fallback={<LoadingScreen />}>
                <Moderation />
              </React.Suspense>
            }
          />
        </Route>
      </Route>,
      <Route
        key={"1" + basePath}
        path={`${basePath}/exhibition/:dir/:name`}
        element={
          <React.Suspense fallback={<LoadingScreen />}>
            <Game />
          </React.Suspense>
        }
        errorElement={<NotFoundPage />}
      />,
      <Route
        key={"2" + basePath}
        path={`${basePath}/display/:dir/:name`}
        element={<Commentary />}
        errorElement={<NotFoundPage />}
      />,
    ];
  };

  return (
    <>
      <Helmet>
        <html lang={methods.locale.replace("_", "-")} />
        <link rel="apple-touch-icon" href={MUSEUM_LOGO} />
        <link rel="apple-touch-icon" sizes="72x72" href={MUSEUM_LOGO} />
      </Helmet>

      <Routes>
        <Route
          path="*"
          element={
            <Navigate
              replace
              to={`/${preferredLanguage}/404${window.location.search}`}
            />
          }
        />

        <Route
          path={`/monitor`}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <Monitor />
            </React.Suspense>
          }
        />

        <Route
          path={`/monitor/mensa`}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <MonitorMensa />
            </React.Suspense>
          }
        />

        <Route
          path={`/monitor/christmas`}
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <MonitorChristmas />
            </React.Suspense>
          }
        />

        <Route
          path={"/mensa"}
          element={
            <Navigate
              replace
              to={`/${preferredLanguage}/mensa${window.location.search}`}
            />
          }
        />

        <Route
          path="/"
          element={<Navigate replace to={`/${preferredLanguage}`} />}
        />
        <Route
          path={`/about`}
          element={<Navigate replace to={`/${preferredLanguage}/about`} />}
        />
        {/* <Route
          path={`/software`}
          element={<Navigate replace to={`/${preferredLanguage}/software`} />}
        /> */}
        <Route path={`/post/:id`} element={<PostNavigate />} />

        <Route
          path="/admin"
          element={
            <Navigate
              replace
              to={`/${preferredLanguage}/admin${window.location.search}`}
            />
          }
        />
        <Route
          path="/admin/postEditor"
          element={
            <Navigate
              replace
              to={`/${preferredLanguage}/admin/postEditor${window.location.search}`}
            />
          }
        />
        <Route
          path="/admin/moderation"
          element={
            <Navigate
              replace
              to={`/${preferredLanguage}/admin/moderation${window.location.search}`}
            />
          }
        />

        <Route
          path={`/exhibition/:dir/:name`}
          element={<ExhibitionNavigate dir="exhibition" />}
          errorElement={<NotFoundPage />}
        />
        <Route
          path="/ls"
          element={
            <ExhibitionLoadingScreen
              showMessage
              zIndex={1}
              open
              setOpen={(_) => {}}
              showTips
            />
          }
        />

        <Route
          path={`/display/:dir/:name`}
          element={<ExhibitionNavigate dir="comments" />}
          errorElement={<NotFoundPage />}
        />

        <Route
          path="/legacyCampus"
          element={
            <Navigate
              replace
              to={`/${preferredLanguage}/legacyCampus${window.location.search}`}
            />
          }
        />

        {makeBaseRoute("/de")}
        {makeBaseRoute("/en")}
      </Routes>

      <ToastManagerComponent doNotAddDefault={true} />
      <OffcanvasComponent />
    </>
  );
};
export default App;
